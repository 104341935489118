import React, { Component } from "react";
import { Grid, Col, Row} from "react-bootstrap";
import { ExportToCsv } from 'export-to-csv';
import ReactTable from "react-table-6";
import Moment from 'react-moment';
import moment from 'moment';
import PaymentsCard from "components/Card/PaymentsCard.jsx";
import Loading from "components/Loader/loading.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Loader from "components/Loader/loader.jsx";
const Config = require('config');


class Payments extends Component {




    constructor(props){
        super(props);

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
                this.state = {
                  loading: true,
                paymentsdatalist:[],
                owed:"",
                paid:"",
                owedcad:"",
                paidcad:"",
                owedeur:"",
                paideur:"",
                downloading:false,
                  mid:"",
                  kyc:false,
                  from: undefined,
                  to: undefined
                }
               
              //  console.log('this.props', this.props)
            }
               
            
            showFromMonth() {
              const { from, to } = this.state;
              if (!from) {
                return;
              }
            if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
            }
            }
          
            handleFromChange(from) {
              // Change the from date and focus the "to" input field
              this.setState({ from });
   
            }
          
            handleToChange(to) {
              this.setState({ to }, this.showFromMonth);
            }

            
componentWillMount(){
//this.getPaymentsTotals();
 
    var user=JSON.parse(localStorage.getItem('user'));
          
    if (user.data.kyc=='Verified'){this.setState({kyc:true})}else{
this.setState({kyc:false})}
this.getBalances()
  }
  


  getBalances(){
  
    var user=JSON.parse(localStorage.getItem('user'));
    var mid=user.data.merchant_id;
    var token=user.token;


    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'  ,'access-token': token,"mid":mid}
   };
      


    fetch(Config.API+'/api/balances',requestOptions)
    .then( this.handleResponse)
    .then( withdrawdata => this.setState({ paymentsdatalist:withdrawdata.data, downloading: false,mid:mid,loading:false}))
   

  }


  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
}


  

  render() {
    const { downloading } = this.state;

    if(this.state.paid==null){this.setState({ paid:0})};
    if(this.state.paidcad==null){this.setState({ paidcad:0})};
    if(this.state.paideur==null){this.setState({ paideur:0})};

    if (this.state.loading) return <Loader />
    return (
         <div className="main-content">
          
        <Grid fluid>
          <Row>

          <Col lg={12}> {this.state.kyc!=true?<Card 
      warning="true"
                  content={
                  <Row >
                    <Col  className="text-center">
                    
                    <i className="fas fa-exclamation-triangle"></i> Please <a  href={Config.VerifyUrl} target="_blank">Verify</a> your account. <a href={Config.VerifyUrl} target="_blank">Verify Now</a>
    
              
                   
                    </Col>
                  
                  </Row>
                }
              />:<div></div>}</Col>
          <Col lg={12}>  <h3>Current Account Balances</h3>
          <div className="content">
         
        </div>
          </Col>
  
          <Col lg={12}>
       
       <div className="card">
   
          

       <div className="content">

       


   
               <ReactTable
               
                 data={this.state.paymentsdatalist}
                 filterable
                 columns={[
                   {
                     Header: "Coin",
                     accessor:"coin",
                    
                   },
                  
                  
                   {
                     Header: "Total Balance",
                     accessor: "balance"
                   }
                   ,
                   {
                     Header: "Current USD Rate",
                     accessor: "rate"
                   },
                   {
                     Header: "Balance in USD",
                     accessor: "usd"
                   }
                  
                  
                   
                 
                  
                   
                   
                 ]}
                 noDataText= "No Balances Found"
                 defaultPageSize={30}
              
                 showPaginationBottom={true}
                 className="-striped -highlight"
               />
             

            
       </div>
       </div></Col>




          </Row>
        </Grid>
      </div>









    );
  }
}

export default Payments;