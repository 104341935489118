
import Settings from "views/Settings/Settings.jsx";
import BankingInfo from "views/Settings/banking-info.jsx";
import AccountInfo from "views/Settings/account-info.jsx";
import Profile from "views/Profile/profile.jsx";
import Balances from "views/Balances/balances.jsx";
import BalancesHistorical from "views/Balances/balanceshistorical.jsx";
import Payouts from "views/Payouts/payouts.jsx";
import Transactions from "views/Transactions/transactions.jsx";
import Addresses from "views/Addresses/addresses.jsx";
import  ApiInfo from "views/Settings/api-info.jsx";
import  ApiKeys from "views/Settings/apis.jsx";





import pagesRoutes from "./pages.jsx";


var dashboardRoutes = [
  
  {
    collapse: true,
  menuhide:false,
  state: "openBalances",
  name: "Account Balances",
  icon: "fal fa-balance-scale",
  
  views: [
    {
      menuhide:false,
      path: "/account_balances/current",
      name: "Current",
      icon: "fal fa-balance-scale",
      component: Balances
    },
    {
      menuhide:false,
      path: "/account_balances/historical",
      name: "Historical",
      icon: "fal fa-balance-scale",
      component: BalancesHistorical
    }]
  },{
 
    path: "/payout_history",
    name: "Payout History",
    icon: "fal fa-file-invoice-dollar",
    component: Payouts
   
   
  },{
 
    path: "/payments_received",
    name: "Payments Received",
    icon: "fal fa-hand-holding-usd",
    component: Transactions
   
   
  },{
 
    path: "/addresses",
    name: "Addresses",
    icon: "fal fa-wallet",
    component: Addresses
   
   
  },
      {
        menuhide:true,
        path: "/profile",
        name: "Profile",
        component: Profile
      },
     
     

      
      
  { redirect: true, path: "/", pathTo: "/account_balances/current", name: "Home" }


];
export default dashboardRoutes;
