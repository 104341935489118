import React, { Component } from "react";
import { Grid, Col, Row} from "react-bootstrap";
import { ExportToCsv } from 'export-to-csv';
import ReactTable from "react-table-6";
import Moment from 'react-moment';
import moment from 'moment';
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
const Config = require('config');


class Payouts extends Component {




    constructor(props){
        super(props);

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
                this.state = {
                  loading: true,
                Payoutsdatalist:[],
                owed:"",
                paid:"",
                owedcad:"",
                paidcad:"",
                owedeur:"",
                paideur:"",
                  mid:"",
                  kyc:false,
                  from: undefined,
                  to: undefined
                }
               
              //  console.log('this.props', this.props)
            }
               
            
            showFromMonth() {
              const { from, to } = this.state;
              if (!from) {
                return;
              }
            if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
            }
            }
          
            handleFromChange(from) {
              // Change the from date and focus the "to" input field
              this.setState({ from });
   
            }
          
            handleToChange(to) {
              this.setState({ to }, this.showFromMonth);
            }

            
componentDidMount(){
//this.getPayoutsTotals();
    this.getPayouts();
    var user=JSON.parse(localStorage.getItem('user'));
          
    if (user.data.kyc=='Verified'){this.setState({kyc:true})}else{
this.setState({kyc:false})}

  }
  
  getPayouts(){

    var user=JSON.parse(localStorage.getItem('user'));
    var mid=user.data.merchant_id;
    var token=user.token;
var startDate=this.state.from;
var endDate=this.state.to;

    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'  ,'access-token': token,"mid":mid},
      body:JSON.stringify({startDate,endDate})
   };
      


    fetch(Config.API+'/api/withdraws',requestOptions)
    .then( this.handleResponse)
    .then( withdrawdata => this.setState({ Payoutsdatalist:withdrawdata.data, loading: false,mid:mid}))
   

  }




  export(){ 
    const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        filename:'Payouts',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: false,
        title: '',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: ['Ref Id','Alt5 Id','Coin','Transaction Type','TX fee','Amount','Price','$ TX fee','$ Amount','Transaction Hash','Address','Tag','Status','Date']
      };
     
    const csvExporter = new ExportToCsv(options);


csvExporter.generateCsv(this.state.Payoutsdatalist)

      

  }

  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
}


  

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    const FORMAT = 'YYYY-MM-DD';
    if(this.state.paid==null){this.setState({ paid:0})};
    if(this.state.paidcad==null){this.setState({ paidcad:0})};
    if(this.state.paideur==null){this.setState({ paideur:0})};
    if (this.state.loading) return <Loader />
    return (
         <div className="main-content">
          
        <Grid fluid>
          <Row>

  
<Col lg={12}>

          <div className="card">
          <h5 className="header">Payout History</h5>
             

          <div className="content">
          <div className="options"> <Button  bsStyle="default" onClick={() => this.export()}>
                       Export
                        <span className="btn-label btn-label-right">
                           <i className="fa fa-download " />
                        </span>
                      </Button>
            <div className="DateSelector">   <div className="InputFromTo">
        <DayPickerInput
          value={formatDate(new Date(),'YYYY-MM-DD')}
          placeholder="From"
          placeholder="From"
          format={FORMAT}
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange}
        />{' '}
        —{' '}
        <span className="InputFromTo-to">
          <DayPickerInput
            ref={el => (this.to = el)}
            value={formatDate(new Date(),'YYYY-MM-DD')}
            placeholder="From"
            placeholder="To"
            format={FORMAT}
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 2,
            }}
            onDayChange={this.handleToChange}
          />
             <Button  bsStyle="default" onClick={() => this.getPayouts()}>
                       Go
                      
                      </Button>
        </span>
          
        
         
       
        
        </div></div></div>
                      <ReactTable
                  
                  data={this.state.Payoutsdatalist}
                  filterable
                  columns={[
                    {
                      Header: "Ref Id",
                      accessor:"ref_id",
                     
                      
                    },
                    {
                      Header: "Alt5 Id",
                      accessor:"id",
                     
                      
                    },
                    {
                      Header: "Date",
                      accessor:"date_time",
                     id:"date_time",
                      Cell: props => {
                        return <Moment format="YYYY-MM-D H:mm:ss">{props.value}</Moment>;
                      },
                    },
                    {
                     
                 
                      Header: "Coin",
                      accessor:"coin"}
                      ,
                      {
           
                        filterable: false,
                        Header: "Transaction Type",
                        accessor:"transaction_type"}
                        ,
                    {
                     
          
                      Header: "Amount",
                      accessor:"amount"}
                      ,
                    {
                      Header: "TX Fee",
              
                      accessor:"fnetwork_fee"    }
                       ,{
                          Header: "$ Price",
                          id: "price",
                          accessor: d => {return d.price==null
                            ? 0
                          : d.price },
                          Cell: props => props.value.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                        },
                        {
                          id:"amount_in_fiat",
                          Header: "$ Amount",
                       
                          accessor: d => {return d.fee_in_fiat==null
                            ? 0
                          : d.amount_in_fiat},
                          Cell: props => props.value.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                        },
                        {
                          id:"fee_in_fiat",
                          Header: "$ TX Fee",
                       
                          accessor: d => {return d.fee_in_fiat==null
                            ? 0
                          : d.fee_in_fiat},
                          Cell: props => props.value.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                        } ,
                      {
                        Header: "Transaction Id",
                      
                        accessor:"txid"    }

                        ,
                      {
                        Header: "Address",
                      
                        accessor:"address"    }
                        ,
                      {
                        Header: "Tag",
                      
                        accessor:"tag"    }
                        ,
                      {
                        Header: "Status",
                      
                        accessor:"status"    }
                      
                    
                    
                  ]}
                  noDataText= "No Payouts Found"
                  defaultPageSize={10}
                  showPaginationBottom
                  showPaginationBottom={true}
                  className="-striped -highlight"
                  
                />
              

          </div>
          </div></Col>
          </Row>
        </Grid>
      </div>









    );
  }
}

export default Payouts;