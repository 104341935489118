let Config = {
API:'https://cashiersandapi.digitalpaydev.com:5000',
//API:'http://localhost:5000',
  BtcTxUrl:'https://blockexplorer.one/btc/testnet/tx/',
  BhcTxUrl:'https://blockexplorer.one/bch/testnet/tx/',
  LtcTxUrl:'https://blockexplorer.one/ltc/testnet/tx/',
  DogeTxUrl:'https://blockexplorer.one/doge/testnet/tx/',
  EthTxUrl:'https://ropsten.etherscan.io/tx/',
  MinInvoice:4,
  gtag:'AW-000000000',
  Company:'Digital Pay Dev',
  Website:'https://cashiersanddash.digitalpaydev.com',
  WidgetUrl:'https://digitalpayev.com/widget/paydev.js?v4',
  WidgetName:'digitalpay',
  VerifyUrl:'https://digitalpaydev.com/merchant_verify/'
};
module.exports = Config;