import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";



import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckboxv2.jsx";
import Select from "react-select";
import "react-select/dist/react-select.css";
const Config = require('config');

var md5 = require('md5');

class AccountInfo extends Component {
  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      error:false,
      loading: true,
      alert: null,
      newuser:false,
      show: false,

      usertype:0,
      active:0,
      activeval:false,
      usernamen:'',
      firstname:"",
      lastname:"",
      merchant_id:0,
      usernameError:null,
      firstnameError:null,
     lastnameError:null,
merchantAccounts:[],
      passwordError:null,
      merchant_idError:null,
      user_id:null,
      password:"",
      admin:0,
      adminval:false,
      update:false
     
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAdminChange = this.handleAdminChange.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleValidate = this.handleValidate.bind(this);

 
  }

  componentDidMount(){
 this.props.match.params.uid!='new'
   ? this.setState({user_id:this.props.match.params.uid},this.getuserinfo())
   : this.setState({loading: false,newuser:true})


  }


 async  updatestates(user){

    await this.setState({admin:user.data.madmin,usernamen:user.data.username,firstname:user.data.firstname,merchant_id:user.data.merchant_id,active:user.data.active,lastname:user.data.lastname,loading:false})
    if(this.state.admin==1){this.setState({adminval:true})}else{this.setState({adminval:false})}
    if(this.state.active==1){this.setState({activeval:true})}else{this.setState({activeval:false})}
  
 

}
  getuserinfo(){
    var user=JSON.parse(localStorage.getItem('user'));

    const user_id=this.props.match.params.uid;
         var mid=user.data.merchant_id;
    const requestOptions = {
      headers: { 'access-token': user.token,'mid':mid },
    };

    fetch(Config.API+'/api/users/'+user_id,requestOptions)
    .then( this.handleResponse)
      .then( user => this.updatestates(user))
  

     
  
    }




    updateuserinfo(firstname,lastname,active,admin,user_id){
      var user=JSON.parse(localStorage.getItem('user'));
           var mid=user.data.merchant_id;
var token=user.token;
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':mid},
         body: JSON.stringify({firstname,lastname,active,admin,user_id})
      };
         
    
  return fetch(Config.API+'/api/users/update',requestOptions)
     .then( this.handleResponse)
     .then(this.props.handleClick("tr","success","User has been updated",<span data-notify='icon' className='pe-7s-check' />))
    
    
  }
  
  saveuserinfo(username,password,firstname,lastname,active,admin){
    var user=JSON.parse(localStorage.getItem('user'));
    var mid=user.data.merchant_id;
var token=user.token;
console.log('ok1')
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':mid},
       body: JSON.stringify({ username,password,firstname,lastname,active,admin})
    };
        
  
return fetch(Config.API+`/api/users/create`,requestOptions)
   .then( this.handleResponse)
   .then( user => this.setState({newuser:false,update:true,user_id:user.data.user_id},function(){
    this.props.handleClick("tr","success","User has been created",<span data-notify='icon' className='pe-7s-check' />)
   }))

  
   
}


  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);

        // if (!response.ok) {
            
        //  // const error = (data && data.message);
        //   return 'error'
        // }else{
        //   console.log(data.status)
        //   if(data.status=='error'){
        //     localStorage.removeItem('user');
        //     window.location.reload(true);
        //        return 'error'
        //   }
        

        // }
       

        return data;
    });
}



    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value })

  }
  handleActiveChange(){
    this.state.activeval==true
    ? this.setState({activeval:false,active:0})
 : this.setState({activeval:true,active:1})
    
  }

  handleAdminChange(){
    this.state.adminval==true
    ? this.setState({adminval:false,admin:0})
 : this.setState({adminval:true,admin:1})
    
  }

  handleMerchantSelectChange = (data)=> {
    if(data!==null){
    this.setState({ merchant_id:data.value});}
   
  };

  handleUsertypeSelectChange = (data)=> {
    if(data!==null){
  data.value===0
  ?this.setState({ usertype:data.value})
   : this.setState({ usertype:data.value,merchant_id:0})
    }
  };



   
  handleValidate(e) {


    this.state.password.length< 8
? this.setState({
passwordError: (
<small className="text-danger">Password must be at least 8 characters long</small>
)
})
: this.state.password.search(/\d/) == -1
? this.setState({
 passwordError: (
     <small className="text-danger">Password must contain 1 number</small>
   )
     })
     : this.state.password.search(/[a-zA-Z]/) == -1
? this.setState({
 passwordError: (
     <small className="text-danger">Password must contain 1 letter</small>
   )
     })
    : this.setState({ passwordError: null });


    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    re.test(this.state.usernamen) === false
      ? this.setState({
       usernameError: (
            <small className="text-danger">
              This must be a valid email address.
            </small>
          )
        })
      : this.setState({ usernameError: null });


        this.state.firstname==""
        ? this.setState({
        firstnameError: (
             <small className="text-danger">this field is required</small>
           )
             })
            : this.setState({ firstnameError: null });

            
            this.state.lastname==""
            ? this.setState({
             lastnameError: (
                 <small className="text-danger">this field is required</small>
               )
                 })
                : this.setState({ lastnameError: null });

               

               
               

                    return
}

  handleSubmit(e) {
    e.preventDefault();
  
    const { usernamen,password,firstname,lastname,active,admin,user_id} = this.state;



    if(this.state.newuser){if( this.state.firstnameError === null && this.state.lastnameError === null && this.state.usernameError === null){
 
      this.saveuserinfo(usernamen,md5(password),firstname,lastname,active,admin)
    
    }}else{
        
 if(this.state.firstnameError === null && this.state.lastnameError === null ){
  this.updateuserinfo(firstname,lastname,active,admin,user_id)}}

   }




   render() {
  
    
    
    const {lastname,firstname,usernamen,password,merchant_id,adminval,activeval,merchantAccounts,newuser} = this.state; 


if (this.state.loading) return <Loader />
      return (
      <div className="main-content">
                      
        <Grid fluid>
        <Breadcrumb>
            <Breadcrumb.Item href="/#/users">Users</Breadcrumb.Item>
            <Breadcrumb.Item active>User Info</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
         
       
                    <Col md={12}> 
              <Form horizontal onSubmit={this.handleSubmit}>
                <Card
                  title="User Profile"
                  content={
                    <div>
              
           


              <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Email/Username
                        </Col>
                        <Col  sm={6}>
                        {  newuser==true
                        ?  <FormControl
                            type="text"
                            name="usernamen"
                          value={usernamen}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          : <div className="textdisplay" >{this.state.usernamen}</div>

                    
                        } {this.state.usernameError}
                        </Col>
                      </FormGroup>


        <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         First Name
                        </Col>
                        <Col sm={6}>
                        <FormControl
                            type="text"
                            name="firstname"
                          value={firstname}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.firstnameError}
                    

                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Last Name
                        </Col>
                        <Col  sm={6}>
                        <FormControl
                            type="text"
                            name="lastname"
                          value={lastname}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.lastnameError}
                        </Col>
                      </FormGroup>
                      {  newuser==true
                     ? <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                     Password
                        </Col>
                        <Col sm={6}>
                        <FormControl
                            type="text"
                            name="password"
                          value={password}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.passwordError}
                        </Col>
                      </FormGroup>
                      : <div/>}
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                  Admin Access
                        </Col>
                        <Col sm={6}>
                          
                     

                        <div className="checkbox checkbox-inline">
                        <Checkbox
                        isChecked={adminval}
                        id="2"
                   
                        onChange={this.handleAdminChange}
                      />
      </div>
                 


                        </Col>
                      </FormGroup>
                  
                  
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                  Active
                        </Col>
                        <Col sm={6}>
                          
                     

                        <div className="checkbox checkbox-inline">
                        <Checkbox
                        isChecked={activeval}
                        id="1"
                   
                        onChange={this.handleActiveChange}
                      />
                 
      </div>
                 


                        </Col>
                      </FormGroup>
                     
                    </div>
                  }
                  legend={
                    <Button
                      fill
                      bsStyle="info"
                      type="submit"
                    
                      onClick={this.handleValidate}
                    >
                      Save
                    </Button>
                    
                  }
      
                />
              </Form>
            </Col>
                 
                  </Row>
               
        </Grid>
      </div>
    );
  }
}

export default AccountInfo ;
