import React, { Component } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import Radio from "components/CustomRadio/CustomRadio.jsx";
import Loader from "components/Loader/loader.jsx";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import {     Breadcrumb,  Grid,
  Row,
  Col,
FormControl,
Form,
FormGroup,
ControlLabel,
  Modal,
  } from "react-bootstrap";

const Config = require('config');
var ps;
export class PaymentsCard extends Component {


  constructor(props){
    super(props);
    this.state = {
      showModal:false,
      showCryptoModal:false,
settled_fees:0,
isSubmitted:false,
settled_payments:0,
owedAmount:0,
paidAmount:0,
availablePriceInUSD:0,
paymentsPriceInUSD:0,
currency:this.props.currency,
mid:this.props.mid,
requestedAmount:0,
_notificationSystem: null,
transaction_ids:[],
alert: null,
      show: false,
      settleType:"Wire"
    }
    this.hideAlert = this.hideAlert.bind(this);
  }
    hideAlert() {
    this.setState({
      alert: null
    });
  }
  cryptoRequest() {
  if(this.state.owedAmount>0)
  {this.setState({
    showCryptoModal:true
    })
  }else{
    this.setState({
      alert: (
        <SweetAlert
        warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Sorry"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
         
          confirmBtnBsStyle="default"
        >You don't have a balance to settle</SweetAlert>
      )
    })

  }
  }



  fiatRequest() {
    if(this.state.owedAmount>=100)
    {
     
    this.setState({
    showModal:true
    })
   }else{
      this.setState({
        alert: (
          <SweetAlert
          warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Sorry"
            onConfirm={() => this.hideAlert()}
          
            confirmBtnBsStyle="btn"
            confirmBtnText="Close"
          >You must have a balance of at least {this.props.currency=='USD' || this.props.currency=='CAD'
          ? '$'
          : '€'}100.00 to settle</SweetAlert>
        )
      })
  
    }
    }
 

 componentDidMount(){
    this.setState({ _notificationSystem: this.refs.notificationSystem });
       this.getPaymentsTotals();

    
      }


  getPaymentsTotals(){

    var user=JSON.parse(localStorage.getItem('user'));

    var token=user.token;
  
    const requestOptions = {
      headers: {'access-token': token,'mid':user.data.merchant_id},
   };
      




    fetch(Config.API+'/api/payments/totals/'+this.state.currency,requestOptions)
    .then( this.handleResponse)
    .then( totaldata => this.setState({ paidAmount:totaldata.data.total_amount_paid,owedAmount:totaldata.data.total_payment_owed,settled_payments:totaldata.data.total_settled_payments,settled_fees:totaldata.data.total_settled_fees,availablePriceInUSD:(totaldata.price*totaldata.data.total_payment_owed).toFixed(2),paymentsPriceInUSD:(totaldata.price*totaldata.data.total_amount_paid).toFixed(2)},function(){

      if (this.state.settled_fees==null){this.setState({settled_fees:0})}

      if (this.state.settled_payments==null){this.setState({settled_payments:0})}
    }));
   


  }


  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
}




handleSubmit() {

this.setState({showModal:false,showCryptoModal:false},function(){
 this.createRequest()
})
 



  }


  
  



handleNotificationClick(position,level,message,title) {
  

  this.state._notificationSystem.addNotification({
    title: title,
    message: (
     message
    ),
    level: level,
    position: position,
    autoDismiss: 3
  });
}

handleRadio = event => {
  const target = event.target;
  this.setState({
    [target.name]: target.value
  },function(){});
};

  render() {
const{isSubmitted}=this.state
     if(this.state.paidAmount==null){
    this.setState({paidAmount:'0'})}
    


    if(this.state.owedAmount==null){
    this.setState({owedAmount:'0'})}
   
    if (this.state.loading) return     <div className="card card-stats">
    <div className="content">
      <div className="row">
        <div className="col-xs-5"><Loader /></div></div></div></div>
    return (
      <div>
          {this.state.alert}
      <NotificationSystem ref="notificationSystem" style={style} />
      <div className="card card-stats">
        <div className="content">
          <div className="row">
            <div className="col-xs-5">
           
              <div className="currency">
                {this.props.currency}
              </div>
            </div>
            <div className="col-xs-7">    
              <div className="numbers">
                <p>Funds Available to Merchant</p>
{this.props.currency=='USD' || this.props.currency=='CAD'
? '$'
: this.props.currency=='EUR'
? '€'
: ''}{this.state.owedAmount}
<div className='usdprice'>( ${this.state.availablePriceInUSD} USD )</div>
              </div>
             <div className="fees">Fees to Date: {this.props.currency=='USD' || this.props.currency=='CAD'
? '$'
: this.props.currency=='EUR' 
? '€'
: ''} {this.state.settled_fees}</div>


              <div className="numbers">
                <p>Payouts to Date Total</p>

                {this.props.currency=='USD' || this.props.currency=='CAD'
? '$'
: this.props.currency=='EUR'
? '€'
: ''}{this.state.paidAmount}
<div className='usdprice'>( ${this.state.paymentsPriceInUSD} USD )</div>

</div>


            </div>
          </div>
        </div>
        <div className="footer">
          <hr />
          <div className="stats">

          </div>
        </div>
      </div>
  
      </div>
    );
  }
}

export default PaymentsCard;
